import React from "react";
import { Route, Redirect } from "react-router-dom";
import { initializeReactGA } from "./utils/index"
import { connect } from "react-redux";

class PrivateRoute extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      verified: true
    }

  }

  componentDidMount() {

    this.setState({ loaded: true })
  }

  render() {
    if (this.state.loaded == true) {
      if (this.props.auth.token !== null && this.props.auth.tenant && this.props.auth.tenant.verified === true) {

        return this.rendered()
      }

      else if (this.props.auth.token == null && this.props.auth.enterPhone === false) {
        return this.enterPhone()
      }
      else if (this.props.auth.token == null && this.props.auth.enterPhone === 'login') {
        initializeReactGA('/connexionTeant')
        return <Redirect to='/connexionTeant' />
      }
      else if (this.props.auth.token == null && this.props.auth.enterPhone === 'signUp') {
        initializeReactGA('tenantVerification')
        return <Redirect to='/tenantVerification' />
      }
      else {
        return this.notauthorizedRedirect()
      }
    }
    else {
      return null
    }
  }


  enterPhone() {
    initializeReactGA('/')
    return (
      <Redirect to='/' />
    )
  }
  notauthorizedRedirect() {
    initializeReactGA('/NotAutherized')
    return (
      <Redirect to='/NotAutherized' />
    )
  }


  rendered() {
    initializeReactGA(this.props.path)
    return (

      // Show the component only when the user is logged in
      // Otherwise, redirect the user to /signin page
      <Route
        component={this.props.component}
        {...this.props}

      />
    );
  }

};
const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps, null)(PrivateRoute);


