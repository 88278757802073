import { reactGoogle } from "../../config/config"
import ReactGA from "react-ga"

const TOKEN_KEY = "user";

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export const isLogin = () => {


  if (
    localStorage.getItem(TOKEN_KEY) === null
  ) {

    return false;
  } else {
    return true;
  }


};
export const isVerified = () => {

  if (
    localStorage.getItem("verified") == true
  ) {
    return true;
  } else {
    return false;
  }
};

export const initializeReactGA = (path) => {
  const newPath = path.split('/')[1]
  ReactGA.initialize(reactGoogle);
  ReactGA.pageview("/" + newPath);
}

