
const intialState = true

const TentantPaymentReducer = (state = intialState, action) => {
  if (action.type === "UPDATE_CHECK") {
    return state = action.payload
  }
  if (action.type === "LOGOUT") { return (state = intialState); }
  else return state
}

export default TentantPaymentReducer