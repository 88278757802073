import detectBrowserLanguage from "detect-browser-language";

let lang = detectBrowserLanguage();

lang = lang.substring(0, 2);
if (lang != "ar") {
  lang = "en";
}

const initialState = {
  token: null,
  lang: lang,
  phone_number: "",
  enterPhone: false,
  tenant: null,
  payloadPaiment: null,
  indication: false,
  rentalsId: "",
  invoiceId: "",
  depositId: '',
  advancePay: false,
  params1: '',
  path: 'home',
  newEmail: '',
  towFactorPhone: '',
  factorAuthentication: false,
  factorToken: null

};

function auth(state = initialState, action) {
  let nextState;
  switch (action.type) {
    case "setLang":
      nextState = {
        ...state,
        lang: action.lang,
      };
      return nextState;

    case "RentalsId":
      nextState = {
        ...state,
        depositId: action.depositId,
        rentalsId: action.rentalsId,
        invoiceId: action.invoiceId,
        params1: action.params1,
        advancePay: action.advancePay,
      };
      return nextState;
    case 'DepositId':
      nextState = {
        ...state,
        depositId: action.depositId,
        rentalsId: action.rentalsId,
        invoiceId: action.invoiceId,
        params1: action.params1,
        advancePay: action.advancePay,
      };
      return nextState;
    case "SET_PARAMS_PAYADVANCED":
      nextState = {
        ...state,
        params1: action.value,
        depositId: action.depositId,
        rentalsId: action.rentalsId,
        invoiceId: action.invoiceId,
      };
      return nextState;
    case "AdvancedPay":
      nextState = {
        ...state,
        advancePay: action.advancePay,
      };
      return nextState;

    case "InvoiceId":
      nextState = {
        ...state,
        depositId: action.depositId,
        invoiceId: action.invoiceId,
        rentalsId: action.rentalsId,
        params1: action.params1,
        advancePay: action.advancePay,
      };
      return nextState

    case "SETUSERANDTOKEN":
      nextState = {
        ...state,
        token: action.token,
        tenant: action.tenant,
        factorToken: null
      };
      return nextState;
    case "SET_Tenant":
      let oldState = { ...state }
      let newTnt = oldState.tenant
      newTnt.full_name = action.tenant.full_name
      newTnt.email = action.tenant.email
      newTnt.documents = action.tenant.documents
      newTnt.gender = action.tenant.gender
      newTnt.id_Number = action.tenant.id_Number
      newTnt.phone_number = action.tenant.phone_number
      newTnt.photo = action.tenant.photo

      nextState = {
        ...state,
        tenant: newTnt,
      };
      return nextState;
    case "PAYMENTINFO":
      nextState = {
        ...state,
        payloadPaiment: action.payloadPayment,
      };
      return nextState;
    case "SET_NUMBER":
      return Object.assign({}, state, {
        ...state,

        phone_number: action.phone_number,
        enterPhone: action.enterPhone,
      });
    case "EDIT_NUMBER":
      let PreviousState = { ...state }
      let newTenant = PreviousState.tenant
      newTenant.phone_number = action.phone_number
      return {
        ...state,
        tenant: newTenant

      }
    case "SET_NUMBER_ForgetPassword":
      nextState = {
        ...state,
        phone_number: action.phone_number,
      };
      return nextState;
    case "LOGOUT":
      return (state = initialState);
    case "VERIFY_ME":
      nextState = {
        ...state,
        indication: action.indication,
      };
      return nextState;

    case "CLAIMS_MAIL":
      return Object.assign({}, state, {
        ...state,
        newEmail: action.value.mail,
      });
    case "CHANGE_USER_MAIL":
      return Object.assign({}, state, {
        ...state,
        newEmail: action.value.mail,
        tenant: { ...state.tenant, email: action.value.email }
      });
    case "SET_MULTIFACTOR":
      return Object.assign({}, state, {
        ...state,
        tenant: { ...state.tenant, factorAuthentication: action.tow_factor }
      });
    case "setfactorAuthentication":
      return Object.assign({}, state, {
        ...state,
        factorAuthentication: action.factorAuthentication,
        factorToken: action.token
      });
    default:
      return state;
  }
}

export default auth;
