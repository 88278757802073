import React from "react";
import { Route, Redirect } from "react-router-dom";

import { initializeReactGA } from "./utils";
import { connect } from "react-redux";

class VerifRoute extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }
  componentDidMount() {
    this.setState({
      loading: true,
    });
  }

  render() {
    if (

      this.props.auth.token == null && this.props.auth.enterPhone === false
    ) {
      initializeReactGA('/')
      return <Redirect to="/" />;
    } else if (this.props.auth.token == null && (this.props.auth.enterPhone === 'signUp' || (this.props.auth.enterPhone === 'login' && this.props.auth.factorAuthentication))) {
      initializeReactGA(this.props.path)
      return <Route component={this.props.component} />
    } else if (this.props.auth.token == null && this.props.auth.enterPhone === 'login' && !this.props.auth.factorAuthentication) {
      initializeReactGA('/connexionTeant')
      return <Redirect to='/connexionTeant' />
    } else {
      initializeReactGA('/NotAutherized')
      return <Redirect to="/NotAutherized" />;
    }
  }
}


const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps, null)(VerifRoute);
