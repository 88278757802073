import React from "react";
import { Route, Redirect } from "react-router-dom";
import { initializeReactGA } from "./utils/index"

import { connect } from "react-redux";

class RestrictedRoute extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }
  componentDidMount() {
    this.setState({
      loading: true,
    });
  }
  const

  render() {
    if (this.props.auth.token == null && this.props.auth.enterPhone === false) {
      initializeReactGA('/')
      return <Redirect to="/" />;
    } else if (this.props.auth.token == null && this.props.auth.enterPhone === 'login') {
      initializeReactGA(this.props.path)
      return <Route component={this.props.component} />
    } else if (this.props.auth.token == null && this.props.auth.enterPhone === 'signUp') {
      initializeReactGA("/tenantVerification")
      return <Redirect to='/tenantVerification' />
    }
    else {
      initializeReactGA('/')
      return <Redirect to="/" />;
    }
  }
}


const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps, null)(RestrictedRoute);
