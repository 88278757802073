import detectBrowserLanguage from "detect-browser-language";

let lang = detectBrowserLanguage();

lang = lang.substring(0, 2);
if (lang != "ar") {
  lang = "en";
}

const initialState = {
  id_conversation: null,
  full_name: null,
  photo: null,
  message: null,
  updatedAt: null,
  id_landlord: null,
  id_admin: null,
  deactivated_landlord: null,
  id_listed_unit: "",
  messageSend: false,
  is_in_liste_conversation: true,
  ReadedMessage: 0,
  intercomDisplay: 1,
  lu: false,
  newMsg: false,
  conversationsList: [],
  landlordList: [],
  key: null,
  online:[]
};

function messenger(state = initialState, action) {
  switch (action.type) {
    case "setReadedMessage":
      return Object.assign({}, state, {
        ...state,
        ReadedMessage: action.value,
      });
    case "DISPLAY_INTERCOM":
      return Object.assign({}, state, {
        ...state,
        intercomDisplay: action.intercomDisplay
      });
    case "SET_ID_CONVERSATION":
      return Object.assign({}, state, {
        ...state,
        id_conversation: action.value,
      });
    case "PUSH_NEW_CONVERSATION":
      let oldState = { ...state };
      let PushNewconversation = oldState.conversationsList
      PushNewconversation.unshift(action.conversation);
      return {
        ...state,
        conversationsList: [
          ...PushNewconversation
        ],
        newMsg: action.newMsg,
        is_in_liste_conversation: action.is_in_liste_conversation,
        id_conversation: action.conversation.id_conversation,
        full_name: action.conversation.name,
        message: action.conversation.text,
        id_landlord: action.conversation.id_receiver,
        lu: action.conversation.lu
        
      };
    case "SET_MY_MESSAGE":
      let PrevState = { ...state };

      let NewconversationsList = PrevState.conversationsList.filter(
        (el) => el.id_conversation === action.id_conversation
      );
      let NewconversationsLandlordsList = PrevState.conversationsList.filter(
        (el) => el.id_conversation === action.id_conversation
      );
      NewconversationsList[0].text = action.value.text;
      NewconversationsList[0].lu = action.lu;
      if (NewconversationsLandlordsList.length) {
        NewconversationsLandlordsList[0].text = action.value.text;
        NewconversationsLandlordsList[0].lu = action.lu;
      }
      let discussion = NewconversationsList[0].discussion;
      let discussionList = NewconversationsLandlordsList.length && NewconversationsLandlordsList[0].discussion;
      if (discussion[0]._id != action._id) {
        discussion.unshift({
          lu: false,
          attachments: action.attachments,
          _id: action._id,
          text: action.value.text,
          sender: action.sender,
          createdAt: action.createdAt,
          updatedAt: action.createdAt,
        });
      }
      if (discussionList && discussionList[0]._id != action._id) {
        discussionList.unshift({
          lu: false,
          attachments: action.attachments,
          _id: action._id,
          text: action.value.text,
          sender: action.sender,
          createdAt: action.createdAt,
          updatedAt: action.createdAt,
        });
      }
      return {
        ...state,
        key: action.key,
        conversationsList: [
          ...NewconversationsList,
          ...state.conversationsList.filter(
            (el) => el.id_conversation !== action.id_conversation
          ),
        ],
        text: action.value.text,
        landlordList: [
          ...NewconversationsLandlordsList,
          ...state.landlordList.filter(
            (el) => el.id_conversation !== action.id_conversation
          ),
        ]
      };

    case "CONVERSATION_INFO":
      let PreviousStates = { ...state }
      let NewconversationsListe = PreviousStates.conversationsList.filter(el => el.id_conversation === action.id_conversation)
      NewconversationsListe[0].lu = action.lu
      return Object.assign({}, state, {
        ...state,
        id_conversation: action.value.id_conversation,
        full_name: action.value.name,
        photo: action.value.photo,
        message: action.value.text,
        updatedAt: action.value.updatedAt,
        id_landlord: action.value.id_landlord,
        id_admin: action.value.id_admin,
        deactivated_landlord: action.deactivated_landlord,
        lu: action.lu
      });
    case "SET_CONVERSATION_INFO":
      return Object.assign({}, state, {
        ...state,
        id_conversation: action.value.id_conversation ? action.value.id_conversation : '',
        full_name: action.value.name,
        photo: action.value.photo,
        message: action.value.text,
        updatedAt: action.value.updatedAt,
        id_landlord: action.value.id_landlord,
        id_admin: action.value.id_admin,
        lu: action.lu
      });

    case 'CLEAR_REDUX_MESSENGER': return {
      ...state,
      id_conversation: action.value.id_conversation,
      full_name: action.value.name,
      photo: action.value.photo,
      message: action.value.text,
      updatedAt: action.value.updatedAt,
      id_landlord: action.value.id_landlord,
      newMsg: action.newMsg
    };
    case "SET_ID_SENDER":
      return Object.assign({}, state, {
        ...state,
        id_landlord: action.value,
      });
    case "SET_ID_Listed_Unit":
      return Object.assign({}, state, {
        ...state,
        id_listed_unit: action.value,
      });
    case "CLEAR_REDUX_MESSENGER":
      return (state = {
        id_conversation: null,
        full_name: null,
        photo: null,
        message: null,
        updatedAt: null,
        id_landlord: null,
        key: null,
      });
    case "NEW_MESSAGE_SEND":
      return Object.assign({}, state, {
        ...state,
        messageSend: action.value,
        id_conversation: action.id_conversation,
      });
    case "SET_CONVERSATION_LIST":
      return Object.assign({}, state, {
        ...state,
        key: null,
        conversationsList: action.value,
        is_in_liste_conversation: action.is_in_liste_conversation
      });
    case "SET_LANDLORD_LIST":
      return Object.assign({}, state, {
        ...state,
        key: null,
        landlordList: action.value,
        is_in_liste_conversation: action.is_in_liste_conversation
      });
    case "SET_IS_IN_LIST_CONVERSATION":
      return Object.assign({}, state, {
        ...state,
        is_in_liste_conversation: action.is_in_liste_conversation
      });
    case "SET_MESSAGE_HISTORY":
      let PreviousState = { ...state }
      let AllconversationsList = PreviousState.conversationsList.map(el => el.id_conversation === action.id_conversation ?
        { ...el, discussion: el.discussion.concat(action.value.reverse()) } : { ...el })
      // let discussions = AllconversationsList[0].discussion
      // action.value.reverse().forEach(el => discussions.push(el))
      var uniqueConversations = AllconversationsList.filter(function (elem, pos) {
        return AllconversationsList.indexOf(elem) === pos;
      });
      return {
        ...state,
        conversationsList: [...uniqueConversations],

      }
    case "AJOUTE_MESSAGE_LANDLOARD":
      let MessagePrevState = { ...state }
      let Index = state.conversationsList.findIndex(
        (el) => el.id_landlord === action.id_landlord
      );
      let landlordItem = MessagePrevState.conversationsList[Index]
      landlordItem.discussion.push(...action.value)
      return {
        ...MessagePrevState,
        conversationsList: MessagePrevState.conversationsList.map(el => el.id_landlord === action.id_landlord ? landlordItem : el)
      }


    case "CHANGE_MSG_READ":
      let MessagesPrevState = { ...state }

      let firstMessage = MessagesPrevState.conversationsList.filter(el => el.id_landlord === action.id_landlord)[0].discussion[0].lu
      firstMessage = true
      let oldDiscussion = MessagesPrevState.conversationsList.filter(el => el.id_landlord === action.id_landlord)[0].discussion
      let newStatus = { ...oldDiscussion[0], lu: firstMessage }
      let newDisscussion = oldDiscussion.slice(1, oldDiscussion.lenght)
      newDisscussion.unshift(newStatus)

      return {
        ...MessagesPrevState,
        conversationsList: MessagesPrevState.conversationsList.map(el => el.id_landlord === action.id_landlord ? { ...el, discussion: newDisscussion } : el
        )
      }

    case "DELETE_CONVERSATION":
      let oldStates = { ...state };
      let newConversationList = oldStates.conversationsList.filter(el => el.id_conversation != action.id_conversation)

      return {
        ...state,
        conversationsList: [
          ...newConversationList
        ]
      };
    case "CHANGE_KEY":
      return Object.assign({}, state, {
        ...state,
        key: action.key

      });
    case "ADD_ONLINE_USER":
      let onlineTab =( action.myID !== action.userId) && state.online.indexOf(action.userId) === -1 ? [...state.online,action.userId]: state.online
      return Object.assign({}, state, {
        ...state,
        online: onlineTab

      });

    case "ADD_HORLINE_USER":
      let onlineNewTab = state.online.filter(el => el !== action.userId)
      return Object.assign({}, state, {
        ...state,
        online: onlineNewTab

      });

      case "ADD_ONLINE_Tab":
        return Object.assign({}, state, {
          ...state,
          online: action.online
        });
    case "LOGOUT":
      return (state = initialState);

    default:
      return state;
  }
}

export default messenger;
