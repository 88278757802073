
const intialState = {
  documents: [],
  email: "",
  full_name: "",
  gender: "",
  id_Number: 0,
  phone_number: "",
  photo: "",
  newPhoneNumber: '',
  active_humburger: false
}


const TentantReducer = (state = intialState, action) => {
  if (action.type === "GET_TENANT") {
    return state = action.payload
  }
  if (action.type === "LOGOUT") {
    return state = intialState
  }
  if (action.type === "SET_NEW_NUMBER") {
    return {
      ...state,
      newPhoneNumber: action.new_phone_number,
      phone_number: action.new_phone_number
    }
  }
  if (action.type === 'ACTIVE_HUMBURGER') {
    return {
      ...state,
      active_humburger: action.value
    }
  }
  else if (action.type === "EDIT_NUMBER") {
    return {
      ...state,
      phone_number: action.phone_number,
      newPhoneNumber: action.new_phone_number,
    }
  }
  else if (action.type === "CHANGE_USER_MAIL") {
    return Object.assign({}, state, {
      ...state,
      email: action.value.email,
    });
  }
  else return state
}

export default TentantReducer