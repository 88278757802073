const lang = {
  PageNotFound: "ماتدري وين أنت؟",
  sorry: "ولا نحن ندري",
  btnReturn: "الصفحة الرئيسية",
  PageNotAuthorized: "ماتدري وين أنت؟",
  status: "الحالة",
  /*******************Rentals Table view***************** */
  ARV_Outstanding: "المتبقي",
  ARV_Paid: "مدفوع",
  ARV_Overdue: "دفعات متأخرة",
  ARV_Search: "ابحث",
  ARV_Status: "الحالة",
  ARV_DueDate: "تاريخ الاستحقاق",
  ARvName: "اسم المبنى",
  ARvUnitFloor: "رقم الوحدة والدور",
  ARvNanlord: " المالك",
  ARvTenantName: "المستأجر",
  RTvTransactionNbr: "رقم المعاملات",
  ARV_DatePayment: "تاريخ الدفع وطريقة الدفع ",
  ARVRecord: "تسجيل دفع ",
  ARVReceiv: "عرض الفاتورة",
  ARVRe: "ايصال الدفع",
  ARVRReceipt: "فاتورة",
  ARVRt: "من",
  ARTRTo: "الى",
  ARTAmout: "المبلغ المدفوع",
  ARTPyment: "طريقة الدفع",
  AETRef: "رقم المرجع",
  AETPaymentdate: "تاريخ الدفع",
  PaymentDate: "تاريخ الدفع",
  NumberMonths: "عدد الأشهر",
  AETPaymentmeth: "طريقة الدفع",
  AETPaymentCash: "نقدي",
  AETPaymentCheque: "شيك مصرفي",
  AETPaymentCreditcrd: "بطاقة الائتمان",
  AETPaymentBque: "حوالة بنكية",
  selectAe: "اختر",
  selectAeUnit: "اختر الوحدة العقارية",
  selectAeProperty: "اختر المبنى",
  refnmbrRecod: "ادخل الرقم",
  AETCancel: "إلغاء",
  AETSubmit: "تأكيد",
  RTVStsend: "غير مدفوع",
  RTVStpaid: "مدفوع",
  RTVStoverdue: "متأخر",
  deleteUnitt: "مسح الوحدة",
  Export: "استخراج",
  Search: "ابحث",
  RTVRecord: "تسجيل دفع",
  dateSearch: "التاريخ",
  Invoice: "فاتورة",
  UnitRented_KWD: "د.ك",
  UnitRented_export: "استخراج",
  /*----F Pswd -----*/
  ResetPassword: "إعادة تعيين كلمة المرور",
  phoneVericationPlaceHolder: "ادخل رقم هاتفك",
  phoneVerificationButtonBack: "عودة الى تسجيل الدخول",
  forgotPasswordMessage:
    "لقد أرسلنا لك رسالة قصيرة برمز ، يرجى إدخال الرمز للتحقق من رقمك.",
  forgotPasswordWarning: "هذا الرمز غير صالح",
  /**************************SETTINGS******** */
  full_name: "الاسم كاملاً",
  change_password: "تغيير كلمة السر",
  Civil_ID: "البطاقة المدنية (الجهتين)",
  id_number: "الرقم المدني",
  upload_photo: "ارفع الصورة",
  current_password: "كلمة المرور الحالية",
  new_password: "كلمة المرور الجديدة",
  update_password: "تغيير كلمة المرور",
  language: "إختيار اللغة",
  selectLanguage: "حدد لغتك المفضلة",
  english: "الإنجليزية",
  arabic: "العربية",
  /************Tenant phone number detection***********/
  hello_tenant: "تسجيل الدخول",
  enter_tenant_phone: "يرجى إدخال رقم الهاتف",
  enter_phone: "أضف رقم الهاتف",
  Continue: "متابعة",
  youHaveToBeInvited: "يتطلب على مدير العقار أن يدعوك للانضمام. يمكنك المحاولة مرة أخرى أو الذهاب إلى الصفحة الرئيسية",
  smsverificationTenantContinue: "التالي",
  /*****************Tenant psw ******************/
  SignUpPassword: "أنشئ كلمة مرور",
  pswTenantgreat: "إنشاء كلمة المرور",
  pswTenantcreatepsw: "يرجى اختيار كلمة مرور لحسابك المسجل برقم الهاتف",
  pswTenantConfirmPassword: "تأكيد كلمة المرور",
  forgotPasswordMessageWarning1: "الرجاء التأكد من كلمة السر!",
  confirm: "تسجيل الدخول",
  /*****************Tenant login*****************/
  cnxTeantwelcomeBack: "هلا و يا مرحبا",
  cnxTenantEnterPassword: "أدخل كلمة المرور",
  cnxTenantEnterPasswordPlaceholder: "كلمة المرور",
  cnxTenantPassword: "من فضلك أدخل كلمة سر خاصة",
  cnxTenantContinue: "تسجيل الدخول",
  cnxTenantForgotPassword: "نسيت كلمة العبور؟",
  phoneVerificationButtonBackTenant: "عودة الى تسجيل الدخول",

  /*-----------------NavBAr-----------*/
  Statistics: "إحصائيات",
  Pay: "المدفوعات",
  NavbarPay: "المدفوعات",
  Rentals: "الإيجارات",
  Maintenance: "الصيانة",
  /*----F Pswd -----*/
  messagePhoneVerification: "يرجى إدخال رقم هاتفك لإعادة التعيين كلمة السر",
  forgotPasswordThankyou: "شكرا !",
  forgotPasswordResend: "إعادة ارسال الرمز",
  forgotPasswordResetPassword: "إعادة تعيين كلمة المرور",
  forgotPasswordNewPassword: "كلمة المرور الجديدة",
  forgotPasswordConfirmPassword: "تأكيد كلمة المرور",
  forgotPasswordButtonConfirmation: "تأكيد",
  forgotPasswordMessageWarning: "يرجى التأكد من صحة رقمك",
  forgotPasswordPasswordGuidance: "إرشادات كلمة المرور",
  forgotPasswordNumbers: "أرقام",
  Numbers: "أرقام",
  Password_guidance: "إرشادات كلمة المرور",
  Lower_caracter: "أحرف صغيرة",
  Lower_Caracter: "أحرف صغيرة",
  Uper_case: "أحرف كبيرة",
  Min_caracter: "8 أحرف على الأقل",
  forgotPasswordLowerCaracter: "أحرف صغيرة",
  forgotPasswordUperCase: "أحرف كبيرة",
  forgotPasswordMinCaracter: "8 أحرف على الأقل",
  forgotPasswordSpeicalChar: "رمز",
  forgotPasswordConfirm: "تأكيد",
  /**************************SETTINGS******** */
  profile: "الملف الشخصي",
  logout: "تسجيل الخروج",
  email_address: "البريد الالكتروني",
  edit: "تعديل",
  add_your_phone_number: "أضف رقم هاتفك",
  change_your_password: "يمكنك تغيير كلمة المرور الخاصة بك في أي وقت",
  password: "تغيير كلمة المرور",
  Documents: "البطاقة المدنية (الجهتين)",
  phone_number: "رقم الهاتف",
  save: "حفظ",
  Browse: "رفع",
  contact_information: "معلومات ",
  password: "التسجيل وكلمة السر",
  this_password_is_incorrect: "الرجاء التأكد من كلمة السر",
  /************************Tenant Payment****************** */
  Payment: "المدفوعات",
  payAll: "دفع الكل",
  payinAdvance: "ادفع مقدما",
  Due_date: "تاريخ الاستحقاق",
  Payment_Of: "دفع عن شهر",
  Category: "الفئة",
  Property_Unit: "الوحدة العقارية",
  Pay_to: "دفع الى ",
  Rent: "تأجير",
  Rents: "إيجار",
  subject: "الموضوع",
  Subject: "الموضوع",
  /*************************RENTALS ******************************* */
  UnitRented_Beds: "غرف نوم",
  UnitRented_Bathrooms: "حمام",
  UnitRented_SQft: "ق.م",
  active: "نشط",
  expired: "منقضي",
  pending: "قيد الانتظار",

  /*****************Tenant payment**************/
  Total: "الإجمالي",
  pay: "دفع",
  History: "المدفوعات السابقة",

  /********************Tenant history******************/
  tenant_history_date: "تاريخ الإستحقاق",
  tenant_history_category: "الفئة",
  tenant_history_paymentDate: "تاريخ الدفع",
  tenant_history_propUnit: "الوحدة العقارية",
  tenant_history_Amount: "المبلغ (د.ك)",
  tenant_history_method: "طريقة الدفع",
  tenant_history_action: "ملاحظات",
  /**************************Modal Payment *******************/
  AddCard: "إضافة بطاقة",
  Knet: "كي-نت",
  PayNow: "ادفع الآن",
  CVV: "كود الأمان",
  ExpirationDate: "تاريخ إنتهاء الصلاحية",
  CardNumber: "رقم البطاقة",
  FullNameModal: "الإسم بالكامل",
  Amount: "إجمالي المبلغ",
  PayTo: "دفع إلى",
  property: " المبنى",
  Property: "المبنى",
  Checkout: "الدفع",
  PayWith: "إدفع عن طريق",
  enterFullName: "أكتب هنا",
  birth_date: "التاريخ",
  verifyInformation: "الرجاء التثبت من البيانات",
  stay_tuned: "خلك متابع",
  comming_soon: "ستأتي الخاصية قريباً",
  errorMessageAmount: "المبلغ عليه أن يكون أكبر من 1 ك.د",

  /****************maintenance**********************/
  openTicket: "فتح تذكرة ",
  Unresolved: "غير محلولة ",
  Resolved: "محلولة ",
  Unit: "الوحدة ",
  Title: "الموضوع",
  Details: "التفاصيل ",
  Select: "إختر ",
  AddSubject: "أضف الموضوع",
  Message: "الرسالة ",

  maintenanceDeleteTxt: "هل تريد حذف التذكرة ؟",
  maintenanceDeleteBtn: "حذف",
  maintenanceCancelBtn: "الغاء",
  Submit: "تأكيد",
  Cancel: "إلغاء",
  required_unit_name: "اسم الوحدة مطلوب",
  required_title: "الموضوع مطلوب",
  required_details: "تفاصيل التذكرة مطلوبة",
  required_photo: "تفاصيل الصورة مطلوبة",

  UnitSelect: "الوحدة العقارية",
  PaydAdv_Months: "عدد الأشهر",
  PaydAdv_Pay_for: "دفع لاشهر",
  KDW: "د.ك",
  resendCodemessage: "يتم إرسال رمز التحقق مرة أخرى",
  monthToPay: "لديك دفعة مستحقة",
  pleasureMessage: "يرجى الدفع للشهر المستحق أولا",
  /***********************Statistic ***************/
  FilterByUnit: "البحث حسب الوحدة",
  outstanding: "معلقة",
  kwd: "د.ك",
  maintenace: "الصيانة",
  unresolved: "غير محلولة",
  resolved: "محلولة",
  nextPaymentIn: "الدفعة القادمة خلال",
  Days: "يوم",
  contractEndIn: "ينتهي العقد خلال",
  seeAll: "شاهد الكل",
  Month: "شهر",
  Months: "أشهر",
  PropertySelect: 'المبنى',
  next: "متابعة",
  errorMsgCardpayment: 'يجب عليك اختيار البطاقة',
  settings: 'الإعدادات',
  change_photo: 'غير الصوره',
  upload: "رفع",
  empty: 'لا توجد مدفوعات سابقة.',
  empty2: 'ستظهر تفاصيل المدفوعات هنا عند الدفع عن طريق تينانتيف',
  selectPlaceholderEmpty: 'يرجى تحديد المبنى أولاً ',
  emailAddressPlaceholder: 'أدخل بريدك الإلكتروني',
  failed: 'Failed',
  password_not_valid: "الرجاء التأكد من كلمة السر",

  viewContract: "عرض العقد",
  contract: 'عقد',
  Date: 'التاريخ',
  to: 'إلى',
  rent: "مقدار الايجار",
  Deposit: "وديعة التأمين",
  accept_contract: "قبول العرض",
  reject_contract: "رفض",
  loadingHistory: 'جار التحميل...',
  noMore_message: 'لا مزيد من الرسائل',
  update: "تحديث",
  update_number: 'تحديث رقم الهاتف',
  update_number_txt: 'الرجاء إدخال رقم الهاتف الجديد هنا',
  verify: 'تحقق',
  newNumberErr: 'رقم الهاتف لا يزال كما هو',
  numberUsed: 'رقم الهاتف قيد الاستخدام بالفعل!',
  type_here: 'أكتب هنا',
  InvoiceReceipt: "إيصال الفاتورة",
  UploadCivilID: 'تحميل البطاقة المدنية',
  reference_id: 'الرقم المرجعي',
  merchant_id: "معرّف التاجر",
  transaction_id: "رقم المعاملة",
  payment_id: 'معرف الدفع',
  Payment_for: "الدفع مقابل",
  Rent_for: "الدفع مقابل",
  DepositFor: "الدفع مقابل",
  noData: 'لايوجد بيانات!',
  unitStat: 'الوحدة',
  messages: "الرسائل",
  Please_input_your_name: "اسم مطلوب",
  name_not_valid: 'يجب أن يحتوي اسمك على 5 أحرف على الأقل',
  id_req: 'رقم البطاقة المدنية مطلوب',
  cancel: "إلغاء",
  browse: "ارفع",
  or: "او",
  drag_and_drop: "اسحب الملفات",
  By_SignUp: "عند إنشاء حساب، فإنك توافق على",
  terms_and_conditions: " شروط الاستخدام وبيان الخصوصية",
  terms: " شروط الاستخدام",
  conditions: " بيان الخصوصية",
  and: " و",
  all: 'الكل',
  ARFloorNumber: 'رقم الدور',
  ARUnitNumber: 'رقم الوحدة',
  ARPropertyAdr: 'موقع المبنى',
  forbiddenPay: "عذراً، لم يقم مالك العقار بتفعيل خاصية دفع الإيجار أون لاين بعد",
  termsConds: 'شروط الاستخدام',
  email_exist: "البريد الإلكتروني مستعمل",
  knet: 'كي - نت',
  cash: 'نقدي',
  bankTransfer: 'تحويل مصرفي',
  creditCard: 'بطاقة الائتمان',
  cheque: 'شيك مصرفي',
  rentHistory: 'تأجير',
  depositReceipt: "إيصال وديعة التأمين",
  contract_pending_notif1: 'لديك',
  contract_pending_notif2: ' ساعة للدفع وتفعيل عقدك للوحدة',
  contract_success_notif: 'تهنئة! تم تفعيل عقدك الآن.',
  blockedTenant: 'لقد تم منعك من الدفع، تواصل مع المالك للمزيد من المعلومات',
  forbiddenPayDeactivatedLandlord: "حساب المالك معطل، يرجى إعادة تفعيله للوصول إلى حسابك.",
  landlordDeactivated: "حساب المالك معطل. تواصل مع المالك للمزيد من المعلومات",
  sqft: "قدم مربع",
  sqm: "متر مربع",
  update_current_phone: "الرجاء إدخال رقم الهاتف الحالي هنا",
  confirm_verification_code: "تأكيد",
  phone_number_error: "يرجى التحقق من رقم هاتفك ",
  frequencyItem: [
    { label: 'شهري', value: 'Monthly' },
    { label: 'سنوي', value: 'Annually' },
    { label: 'ربع سنوي (كل 3 شهور)', value: 'Quarterly' },
    { label: 'نصف سنوي (كل 6 شهور)', value: 'Semiannually' }
  ],
  frequencyPay: 'تكرار الدفع',
  lease: "عقد الإيجار",
  about_to_end1: "لديك ",
  about_to_end2: " علي وشك الانتهاء",
  contracts: "  عقود ",
  /* ------------claims--------------*/
  claims: 'طلبات',
  submit_claim: ' تقديم طلب',
  claim_type: 'نوع الطلب',
  slect_claim: 'اختر نوع الطلب',
  general_claim: 'طلب عام',
  payment_claim: 'طلب مالي',
  new_claim: 'تقديم طلب جديد',
  customer_information: 'معلومات عن المستخدم',
  mobile_number: 'رقم الهاتف',
  issue_information: 'معلومات عن المشكلة',
  complaint: 'الشكوى',
  support_doc: 'إرفاق المستندات الداعمة',
  browsee: "ارفع",
  dnd: 'سحب وإسقاط الملفات',
  forr: '* سيتم الرد على ',
  forrr: '* في ما يتعلق',
  claimsGeneral: 'الطلبات العامة ',
  claimsPay: 'الطلبات المالية ',
  generalDuration: '  خلال 15 يوم عمل. قد نتواصل معك للحصول على معلومات اضافية عن الشكوى',
  payDuration: 'عبر “Knet” يصلكم الرد في غضون 2 إلى 7 أيام عمل. في حال كان طلبك غير مكتمل ،سنقوم بالاتصال بك لإكمال معلوماتك في موعد أقصاه 2 أيام العمل',
  select_prop: 'إختر المبنى',
  select_unit: 'إختر الوحدة',
  receipt_number: 'رقم إيصال',
  transaction_date: 'تاريخ العملية',
  claims_rules: 'أقر بموجب هذا بأن جميع المعلومات المذكورة أعلاه حقيقية وصحيحة ، وأتحمل المسؤولية الكاملة عن عدم صحة هذه المعلومات.',
  send_claim: 'ارسال الطلب',
  search_receipt: 'إختر رقم إيصال',
  amount_refund: 'المبلغ المطلوب استرداده',
  add_amount: 'أضف المبلغ',
  you_done: 'أنت انهيت !',
  payment_done: 'تم تحويل شكواك إلى القسم المختص وسيتم الاستجابة لطلبك في غضون 7 أيام .',
  general_done: 'تم تحويل شكواك إلى القسم المختص وسيتم الاتصل بك في غضون 15 يوم .',
  behalf_landlord: 'بالنيابة عن المالك',
  required_mail: 'بريدك الالكتروني مطلوب',
  claims_history: 'سجل المطالبات ',
  dateTime: 'التاريخ والوقت',
  viewClaim: 'عرض الطلب',
  statusItem: [
    { label: 'قيد النظر', value: 'in progress' },
    { label: 'مرسل', value: 'unresolved' },
    { label: 'تم الحل', value: 'resolved' }
  ],
  paymentClaimDays: 'حتى إلى 7 أيام عمل',
  generalClaimDays: 'حتى 15 يوم عمل',
  name: "اسم",
  Email: "البريد الالكتروني",
  dateSearch: "التاريخ",
  unit: "الوحدة ",
  write_msg: 'اكتب رسالة',
  valid_email: " يرجى إدخال البريد الإلكتروني الصحيح",
  write_msg: 'اكتب رسالة',
  general: 'طلب عام',
  payment: 'طلب مالي',
  Action: "الإجراء",
  Description: "التفاصيل",
  BruteForceError: "لقد تم حظرك لمدة 5 دقائق. يرجى إعادة المحاولة لاحقًا.",
  multiFactorAuth: "المصادقة الثنائية",
  multiFactorAuth1: "سجل الدخول باستخدام المصادقة الثنائية ",
  BruteForceError: "لقد تم حظرك لمدة 5 دقائق. يرجى إعادة المحاولة لاحقًا.",
  off: 'معطل',
  on: 'نشطة',
  year: 'سنوات',
  payment_of: "دفع عن شهر",
  monthly_rent: "الإيجار الشهري",
  refNumber: "الرقم المرجعي",
  MailVerifSuccessfully: 'شكراً لك!',
  MailVerifFailed: 'فشل التحقق من البريد الإلكتروني!',
  MailVerifDashboard: 'عودة للصفحة الرئسية',
  goToDashoardSuccess: 'تم التحقق من البريد الإلكتروني بنجاح.',
  goToDashoardFailed: 'إما أن الرابط قد انتهت صلاحيته أو أنك لم تنسخ العنوان بشكل صحيح.',
  no_msg: 'قم بالتواصل و مشاركة الملفات مع المالك أو فريق الدعم لتينانتيف من خلال أداة المراسلة الخاصة بنا .',
  discount: 'خصم',
  arabic_numbers_error_message: "يرجي استخدام ارقام انجليزيه فقط",
  civil_id_equal_to: "يجب ان يحتوي الرقم المدني علي 12 رقم",
  contract_renewed: "* تم تجديد مدة العقد من قبل المؤجر من",
  contract_renewed_with_rental: "بمبلغ إيجار شهري",
  endDate: "الى",
  KWD: "د.ك",
  dayToAccept: 'لديك 24 ساعة لقبول عقد الوحدة ',
  pasword_in_latin_caracters: "يجب أن تحتوي كلمة المرور فقط على أحرف وأرقام لاتينية ، وأحد هذه العلامات المميزة: d! @ # $٪ ^ & * () _ + \ - = \ [\] {}؛ ': \ \" |،. <> \ /؟",
  forbiddenPayDeposit: "لا يمكنك الدفع مقدمًا ، عليك دفع وديعة التأمين أو الأشهر السابقة",
  youEnteredAnInvalidPhoneNumber: "لقد ادخلت رقم هاتف خاطئ ، يرجى التحقق أو",
  signUp: "التسجيل",
  menuEnglish: "English",
  newPasswordTenant: "من فضلك ادخل كلمة سر جديدة",
};
export default lang;
