import React from "react";
import { Route, Redirect } from "react-router-dom";

import { initializeReactGA } from "./utils";
import { connect } from "react-redux";

class SignUpRoute extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }
  componentDidMount() {
    this.setState({
      loading: true,
    });
  }

  render() {
    if (

      this.props.auth.token == null && this.props.auth.indication === false
    ) {
      initializeReactGA("/tenantVerification")
      return <Redirect to="/tenantVerification" />;
    } else if (this.props.auth.token == null && this.props.auth.indication === true) {
      initializeReactGA("/login")
      return <Route component={this.props.component} />
    } else {
      initializeReactGA("/")
      return <Redirect to="/" />;
    }
  }
}


const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps, null)(SignUpRoute);
