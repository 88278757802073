import React, { Component } from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import translator from "../../lang/translator";
import pageNotFound from "../../assets/404/illust-notfound.png";
import logo from "../../assets/404/logo.svg";
import ArabicLogo from "../../assets/images/ArabicLogo2.svg";
import betaLogo from "../../assets/images/betaLogo.svg";
class ErrorNotFound extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.lang = translator(this.props.lang);
  }
  render() {

    return (
      <div className={this.props.lang + "_containerPageNotFound"}>
        <Row>
          <Col
            offset={3}
            style={
              this.props.lang === "ar"
                ? { display: "none" }
                : { paddingTop: "30px" }
            }
          >
            <Link to="/">
              <div className="navbar_logo_container">
                <img src={logo} />
                <div className={this.props.lang + "_beta_logo_navbar_container"}>
                  <img className="beta_logo_image" src={betaLogo} alt='beta logo' />
                </div>
              </div>
            </Link>
          </Col>
          <Col
            style={
              this.props.lang === "en"
                ? { display: "none" }
                : { paddingTop: "30px", marginRight: "12%" }
            }
          >
            <Link to="/">
              <div className="navbar_logo_container">
                <img src={ArabicLogo} />
                <div className={this.props.lang + "_beta_logo_navbar_container"}>
                  <img className="beta_logo_image" src={betaLogo} alt='beta logo' />
                </div>
              </div>
            </Link>
          </Col>
        </Row>

        <div className={this.props.lang + "_notFoundPageContainer"}>
          <img src={pageNotFound} />
        </div>
        <div className={this.props.lang + "_notFoundPageTitle"}>
          <div>
            {this.lang.PageNotFound}
          </div>
        </div>
        <div className={this.props.lang + "_notFoundPageTitle"}>
          <span>{this.lang.sorry}</span>
        </div>
        <div className={this.props.lang + "_notFoundPageTitle"}>
          <Link to="/">
            <button className={this.props.lang + "_btnReturn"}>
              {this.lang.btnReturn}
            </button>
          </Link>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    lang: state.auth.lang,
  };
};
export default connect(mapStateToProps, null)(ErrorNotFound);
