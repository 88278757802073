import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import React, { lazy, Suspense } from "react";
import NotAutherized from "../components/Pages/NotAutherized";
import { ConfigProvider } from "antd";
import ErrorNotFound from "../components/Pages/ErrorNotFound";
import PrivateRoute from "./PrivateRoute";
import RestrictedRoute from "./restrictedRoute";
import VerifRoute from "./verifRoute";
import SignUpRoute from "./signUpRoute";
import Intercom from "react-intercom";
import { connect } from "react-redux";
import { initializeReactGA } from "./utils/index";
import { apiUrl } from "../config/config";
import EmailVerification from "../components/setting/EmailVerification";
import SingletonSocket from "../singleton/socket";
import AcceptPayAfterPay from "../components/AcceptPayAfterPay"
const renderLoader = () =>
  <div className='lazyText' >
    Tenantive
  </div>
const TenantNumberLazy = lazy(() => import("../components/Tenant/tenantNumber"))
const TenantNumber = (props) => {
  return (
    <Suspense fallback={renderLoader()} >
      <TenantNumberLazy {...props} />
    </Suspense>
  )
}
const PaymentNavLazy = lazy(() => import("../components/Tenant/PaymentNav"))
const PaymentNav = (props) => {
  return (
    <Suspense fallback={renderLoader()} >
      <PaymentNavLazy {...props} />
    </Suspense>
  )
}
const StaticsLazy = lazy(() => import("../components/statics/statics"))
const Statics = (props) => {
  return (
    <Suspense fallback={renderLoader()} >
      <StaticsLazy {...props} />
    </Suspense>
  )
}
const RentalsLazy = lazy(() => import("../components/rentals/Rentals"))
const Rentals = (props) => {
  return (
    <Suspense fallback={renderLoader()} >
      <RentalsLazy {...props} />
    </Suspense>
  )
}
const SettingLazy = lazy(() => import("../components/setting/setting"))
const Setting = (props) => {
  return (
    <Suspense fallback={renderLoader()} >
      <SettingLazy {...props} />
    </Suspense>
  )
}
const MaintenanceLazy = lazy(() => import("../components/Maintenance/maintenance"))
const Maintenance = (props) => {
  return (
    <Suspense fallback={renderLoader()} >
      <MaintenanceLazy {...props} />
    </Suspense>
  )
}
const MessageLazy = lazy(() => import("../components/message/message"))
const Message = (props) => {
  return (
    <Suspense fallback={renderLoader()} >
      <MessageLazy {...props} />
    </Suspense>
  )
}
const ConnexionTenantLazy = lazy(() => import("../components/Tenant/connexionTenant"))
const ConnexionTenant = (props) => {
  return (
    <Suspense fallback={renderLoader()} >
      <ConnexionTenantLazy {...props} />
    </Suspense>
  )
}
const ForgotPasswordTenantLazy = lazy(() => import("../components/Tenant/forgotPasswordTeant"))
const ForgotPasswordTenant = (props) => {
  return (
    <Suspense fallback={renderLoader()} >
      <ForgotPasswordTenantLazy {...props} />
    </Suspense>
  )
}
const ResetPasswordTenantLazy = lazy(() => import("../components/Tenant/resetPasswordTenant"))
const ResetPasswordTenant = (props) => {
  return (
    <Suspense fallback={renderLoader()} >
      <ResetPasswordTenantLazy {...props} />
    </Suspense>
  )
}
const PswTenantLazy = lazy(() => import("../components/Tenant/pswTenant"))
const PswTenant = (props) => {
  return (
    <Suspense fallback={renderLoader()} >
      <PswTenantLazy {...props} />
    </Suspense>
  )
}
const SmsVerificationLazy = lazy(() => import("../components/Tenant/smsVerification"))
const SmsVerification = (props) => {
  return (
    <Suspense fallback={renderLoader()} >
      <SmsVerificationLazy {...props} />
    </Suspense>
  )
}
const SmsVerificationTenantForgotPasswordLazy = lazy(() => import("../components/Tenant/smsVerificationTenantForgotPassword"))
const SmsVerificationTenantForgotPassword = (props) => {
  return (
    <Suspense fallback={renderLoader()} >
      <SmsVerificationTenantForgotPasswordLazy {...props} />
    </Suspense>
  )
}
const AdvancedPayLazy = lazy(() => import("../components/Tenant/advancedPay"))
const AdvancedPay = (props) => {
  return (
    <Suspense fallback={renderLoader()} >
      <AdvancedPayLazy {...props} />
    </Suspense>
  )
}
const AccessDashbordLazy = lazy(() => import("../components/AccessDashbord/AccessDashbord"))
const AccessDashbord = (props) => {
  return (
    <Suspense fallback={renderLoader()} >
      <AccessDashbordLazy {...props} />
    </Suspense>
  )
}
const TenantViewReceiptLazy = lazy(() => import("./../components/Tenant/tenantViewReceipt"))
const TenantViewReceipt = (props) => {
  return (
    <Suspense fallback={renderLoader()} >
      <TenantViewReceiptLazy {...props} />
    </Suspense>
  )
}
const TermsAndConditionsLazy = lazy(() => import("../components/TermsAndCondition/TermsAndConditions"))
const TermsAndConditions = (props) => {
  return (
    <Suspense fallback={renderLoader()} >
      <TermsAndConditionsLazy {...props} />
    </Suspense>
  )
}
const PrivacyPolicyLazy = lazy(() => import("../components/privacy/PrivacyPolicy"))
const PrivacyPolicy = (props) => {
  return (
    <Suspense fallback={renderLoader()} >
      <PrivacyPolicyLazy {...props} />
    </Suspense>
  )
}

let socket = new SingletonSocket()
class MainRoot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      conversationList: [],
      showView: false,
      unitList: []
    };
  }
  onJoin = (data) => {
    socket.emit('join', data, (error) => { })
  }
  getConversations = async () => {
    await fetch(apiUrl + "api/tenant/viewListChatting", {
      method: "GET",
      mode: "cors",
      headers: { Authorization: "Bearer " + this.props.auth.token },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          let newConversations = responseJson.chatList.map((result) => {
            return {
              id_conversation: result.id_conversation,
              photo: result.photo,
              name: result.full_name,
              text: result.message,
              createdAt: result.createdAt,
              discussion: result.discussion,
              id_landlord: result.id_landlord,
              id_admin: result.id_admin,
              sender: result.sender,
              deactivated_landlord: result.deactivated_landlord,
              lu: result.lu
            };
          });
          newConversations.sort((prevMessage, nextMessage) => {
            return (
              new Date(nextMessage.createdAt) - new Date(prevMessage.createdAt)
            );
          });

          let action = {
            type: "SET_CONVERSATION_LIST",
            value: [...newConversations],
            is_in_liste_conversation: true,
          };
          this.props.dispatch(action);
          this.setState({
            conversationList: [...newConversations],
          });
          const data = { userId: this.props.auth.tenant._id, typeSender: "Tenant", conversations: this.props.messenger.conversationsList.map(el => el.id_conversation) }
          this.onJoin(data)
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  componentDidUpdate(prevProps, prevState) {
    if ((prevProps.messenger.conversationsList.length !== this.props.messenger.conversationsList.length) && this.props.auth.token) {
      const data = { userId: this.props.auth.tenant._id, typeSender: "Tenant", conversations: this.props.messenger.conversationsList.map(el => el.id_conversation) }
      this.onJoin(data)
      // const data1 = { userId: this.props.auth.tenant._id, conversations: this.props.messenger.conversationsList.map(el => el.id_conversation) }
      const data1 = { userId: this.props.auth.tenant._id, conversations: this.props.messenger.conversationsList.map(el => el.id_conversation), conversations: this.props.messenger.conversationsList.map(el => el.id_conversation), id_receiver: this.props.messenger.conversationsList.map(el => el.id_landlord || el.id_admin) }
      socket.emit('online', data1, (error) => { })
    }
    if ((prevProps.auth.token !== this.props.auth.token) && this.props.auth.token) {
      this.getConversations()
      this.getUnitName()
    }
  }

  logout = () => {
    const data1 = { userId: this.props.auth.tenant._id, conversations: this.props.messenger.conversationsList.map(el => el.id_conversation) }
    socket.emit('logout', data1, (error) => { })
    localStorage.removeItem("token");
    let action = { type: "LOGOUT" };
    this.props.dispatch(action);
  };

  componentDidMount = async () => {
    if (this.props.auth.tenant) {
      const data = { userId: this.props.auth.tenant._id, typeSender: "Tenant", conversations: this.props.messenger.conversationsList.map(el => el.id_conversation) }
      this.onJoin(data)
      const data1 = { userId: this.props.auth.tenant._id, conversations: this.props.messenger.conversationsList.map(el => el.id_conversation), conversations: this.props.messenger.conversationsList.map(el => el.id_conversation), id_receiver: this.props.messenger.conversationsList.map(el => el.id_landlord || el.id_admin) }
      socket.emit('online', data1, (error) => { })

    }
    if ((this.props.auth && this.props.auth.advancePay === true) || this.props.auth.depositId) {
      this.getParams();
    }
    this.getConversations();
    await fetch(apiUrl + "api/markConversationAsRead/" + this.props.messenger.id_conversation, {
      method: "GET",
      mode: "cors",
      headers: { Authorization: "Bearer " + this.props.auth.token },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.getConversations();
      })
      .catch((error) => {
        console.error(error);
      });
    if (this.props.auth.tenant) {
      socket.on("sendOnlineTab", (data) => {
        const action = {
          type: "ADD_ONLINE_Tab",
          online: data
        }
        this.props.dispatch(action)
      })
      socket.on("userId", (data) => {
        const action = {
          type: "ADD_ONLINE_USER",
          userId: data,
          myID: this.props.auth.tenant._id
        }
        this.props.dispatch(action)
      })

      socket.on("removeUserId", (data) => {
        const action = {
          type: "ADD_HORLINE_USER",
          userId: data,
        }
        this.props.dispatch(action)
      })
    }
    window.addEventListener('touchmove', (event) => {
      if (this.props.tenant.active_humburger) {
        let action = {
          type: 'ACTIVE_HUMBURGER',
          value: false
        }
        this.props.dispatch(action)
      }
    });
    this.getUnitName()

  }
  getParams = () => {
    const params = window.location.search && window.location.search.split("?");
    const params1 = params && params[1].split("=");
    if (this.props.auth.depositId) {
      let action = { type: "SET_PARAMS_PAYADVANCED", value: params1[1], rentalsId: '', invoiceId: '', depositId: this.props.auth.depositId };
      this.props.dispatch(action);
    } else {
      let action = { type: "SET_PARAMS_PAYADVANCED", value: params1[1], rentalsId: '', invoiceId: '', depositId: '' };
      this.props.dispatch(action);
    }
  };
  getUnitName = async () => {
    const unitList = await fetch(apiUrl + "api/tenant/getUnitByTenantMaintenance", {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + this.props?.auth?.token,
      },
    })
    const unitListJson = await unitList.json();
    if (unitListJson.status === 200) {
      this.setState({
        unitList: unitListJson.message,
      });
    }
  }
  render() {
    const user = {
      user_id: this.props.tenant && this.props.tenant.id,
      name: this.props.tenant && "Tenant " + this.props.tenant.full_name,
    };
    return (
      <ConfigProvider direction={this.props.auth.lang === "en" ? "ltr" : "rtl"}>
        <div
          style={this.props.lang === "en" ? { fontFamily: "SF-pro-Regular" } : {}}
        >
          {this.props.auth && this.props.auth.token !== null && this.props.messenger.intercomDisplay === 1 ? (

            <div className="app">
              <Intercom appID="mk7023kq" {...user} />
            </div>
          ) : null}
          <Router>
            <Switch>
              <Route
                exact
                path="/"
                render={(props) => {
                  initializeReactGA("/");
                  return <TenantNumber {...props} />;
                }}
              />
              <Route
                exact
                path="/NotAutherized"
                render={(props) => {
                  initializeReactGA("/NotAutherized");
                  return <NotAutherized {...props} />;
                }}
              />
              <Route path="/verification/email/:token" component={EmailVerification} />
              <PrivateRoute path="/tenantPay" render={(props) => <PaymentNav {...props} unitList={this.state.unitList} />} />
              <PrivateRoute path="/Statistics" render={(props) => <Statics {...props} unitList={this.state.unitList} />} />
              <PrivateRoute path="/Rentals" render={(props) => <Rentals {...props} unitList={this.state.unitList} />} />
              {<PrivateRoute path={"/Maintenance"} render={(props) => this.state.unitList.length !== 0 && <Maintenance {...props} unitList={this.state.unitList} />} />}
              <PrivateRoute path="/Message" render={(props) => <Message {...props} unitList={this.state.unitList} />} />
              <PrivateRoute path="/settings" render={(props) => <Setting {...props} logout={this.logout} unitList={this.state.unitList} />} />
              <Route
                path="/accessDashbord/:number/:ciphertext"
                render={(props) => {
                  initializeReactGA("/accessDashbord/:number/:ciphertext");
                  return <AccessDashbord {...props} />;
                }}
              />
              <Route exact path="/termsAndConditions" component={TermsAndConditions} />
              <Route exact path="/privacyPolicy" component={PrivacyPolicy} />
              <Route exact path="/acceptPayAfterPay" component={AcceptPayAfterPay} />
              <RestrictedRoute
                path="/connexionTeant"
                component={ConnexionTenant}
              />
              <Route
                path="/forgetPassWord"
                render={(props) => {
                  initializeReactGA("/forgetPassWord");
                  return <ForgotPasswordTenant {...props} />;
                }}
              />
              {(this.props.auth && this.props.auth.depositId) ||
                (this.props.auth && this.props.auth.rentalsId) ||
                (this.props.auth && this.props.auth.advancePay == true) ||
                (this.props.auth && this.props.auth.invoiceId) ? (
                <Route
                  path="/tenantViewReceipt"
                  render={(props) => {
                    initializeReactGA("/tenantViewReceipt");
                    return <TenantViewReceipt {...props} />;
                  }}
                />
              ) : (
                <Route
                  exact
                  path="/NotAutherized"
                  render={(props) => {
                    initializeReactGA("/NotAutherized");
                    return <NotAutherized {...props} />;
                  }}
                />
              )}
              <Route
                path="/resetPasswordTenant"
                render={(props) => {
                  initializeReactGA("/resetPasswordTenant");
                  return <ResetPasswordTenant {...props} />;
                }}
              />
              <PrivateRoute path="/modalTest" component={AdvancedPay} />
              <SignUpRoute path="/passwordTenant" component={PswTenant} />
              <VerifRoute
                path="/tenantVerification"
                component={SmsVerification}
              />
              <RestrictedRoute
                path="/tenantVerificationPsw"
                component={SmsVerificationTenantForgotPassword}
              />
              <Route path="*" exact={true} component={ErrorNotFound} />
            </Switch>
          </Router>
        </div>
      </ConfigProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tenant: state.tenant,
    auth: state.auth,
    messenger: state.messenger
  };
};
export default connect(mapStateToProps, null)(MainRoot);
