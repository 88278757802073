import { apiUrl } from "../config/config";
import io from "socket.io-client";
const ENDPOINT = apiUrl;

export default class SingletonSocket {
    _socket;
    constructor() {
        if (!SingletonSocket._socket) {
            SingletonSocket._socket = io(ENDPOINT);
        }
        return SingletonSocket._socket;
    }
}