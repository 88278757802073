import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import logger from "redux-logger-simple";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from './reducers/authReducer'
import TentantReducer from './reducers/TenantReducer'
import TentantPaymentReducer from "./reducers/TenantPaymentReducer";
import messenger from "./reducers/messengerReducer";



const persistConfig = {
  key: "User",
  timeout: null,
  storage,
  whitelist: ["tenant", "auth", "messenger"],
  blacklist: []
};
const middleware = [thunkMiddleware];
const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && process.env.NODE_ENV !== 'production'

    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    })
    : compose;

const enhancer = composeEnhancers(
  applyMiddleware(...middleware, logger)
  // other store enhancers if any
);
const reducers = combineReducers({
  auth: authReducer,
  tenant: TentantReducer,
  payment: TentantPaymentReducer,
  messenger: messenger,
});

const persistedReducers = persistReducer(persistConfig, reducers);

const store = createStore(persistedReducers, enhancer);

export default store;
export const persistor = persistStore(store);
